// Vue Router
import VueRouter from 'vue-router'
import AuthService from '@/services/auth.service'
import Cookies from 'js-cookie'

// Layout
const DefaultLayout = () => import('@/layouts/Default')

// Pages
const Index = () => import('@/pages/Index')
const Login = () => import('@/pages/login/Index')
const ValidateToken = () => import('@/pages/validate-token/Index')
const ChangePassword = () => import('@/pages/change-password/Index')
const About = () => import('@/pages/about/Index')
const Styleguide = () => import('@/pages/styleguide/Index')

// Error Pages
const Page404 = () => import('@/pages/404')
const Page500 = () => import('@/pages/500')

// Banner Pages
const Banners = () => import('@/pages/banners/Datatable')
const Banner = () => import('@/pages/banners/View')
const Order = () => import('@/pages/banners/Order')

// News Pages
const NewsDatatable = () => import('@/pages/news/Datatable')
const NewsView = () => import('@/pages/news/View')

// News Category Pages
const NewsCategoryDatatable = () => import('@/pages/news-category/Datatable')
const NewsCategoryView = () => import('@/pages/news-category/View')

// Newsletter
const NewsletterDatatable = () => import('@/pages/newsletters/Datatable')
const NewsletterView = () => import('@/pages/newsletters/View')

// Users Pages
const Users = () => import('@/pages/users/Datatable')
const User = () => import('@/pages/users/View')

// Permissions Pages
const Permissions = () => import('@/pages/permissions/Datatable')
const Permission = () => import('@/pages/permissions/View')

// SEO
const SEO = () => import('@/pages/seo/Index')

// Cookies
const CookiesPage = () => import('@/pages/cookies/Index')

// Contacts
const Contacts = () => import('@/pages/contacts/Index')

// Settings
const Settings = () => import('@/pages/settings')

// Router
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/change-password/:token',
      name: 'Alterar senha',
      component: ChangePassword
    },
    {
      path: '/change-password/expired/:token',
      name: 'Alterar senha expirada',
      component: ChangePassword
    },
    {
      path: '/validate-token',
      name: 'Validar o token de acesso',
      component: ValidateToken
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: DefaultLayout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Index
        },
        {
          path: 'styleguide',
          name: 'Styleguide',
          component: Styleguide
        },
        {
          path: 'seo',
          name: 'SEO',
          component: SEO
        },
        {
          path: 'cookies',
          name: 'Cookies',
          component: CookiesPage
        },
        {
          path: 'settings',
          name: 'Configurações',
          component: Settings
        },
        {
          path: 'banners',
          name: 'Banners',
          redirect: 'banners/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              component: Banners
            },
            {
              path: 'create',
              name: 'Cadastrar Banner',
              component: Banner
            },
            {
              path: 'edit/:id',
              name: 'Editar Banner',
              component: Banner
            },
            {
              path: 'order',
              name: 'Ordenar Banner',
              component: Order
            }
          ]
        },
        {
          path: 'news',
          name: 'Notícias',
          redirect: 'news/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              component: NewsDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Notícia',
              component: NewsView
            },
            {
              path: 'edit/:id',
              name: 'Editar Notícia',
              component: NewsView
            }
          ]
        },
        {
          path: 'newsletters',
          name: 'Newsletters',
          redirect: 'newsletters/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              name: '',
              component: NewsletterDatatable
            },
            {
              path: 'create',
              name: 'Nova Newsletter',
              component: NewsletterView
            },
            {
              path: 'edit/:id',
              name: 'Editar Newsletter',
              component: NewsletterView
            }
          ]
        },
        {
          path: 'news-category',
          name: 'Categoria de Notícias',
          redirect: 'news-category/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              component: NewsCategoryDatatable
            },
            {
              path: 'create',
              name: 'Cadastrar Categoria',
              component: NewsCategoryView
            },
            {
              path: 'edit/:id',
              name: 'Editar Categoria',
              component: NewsCategoryView
            }
          ]
        },
        {
          path: 'contacts',
          name: 'Contatos',
          redirect: 'contacts/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              name: '',
              component: Contacts
            },
            {
              path: ':id',
              name: 'Detalhes da mensagem',
              component: Contacts
            }
          ]
        },
        {
          path: 'users',
          name: 'Usuários',
          redirect: 'users/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              name: '',
              component: Users
            },
            {
              path: 'create',
              name: 'Novo Usuário',
              component: User
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: User
            }
          ]
        },
        {
          path: 'permissions',
          name: 'Permissões',
          redirect: 'permissions/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              name: '',
              component: Permissions
            },
            {
              path: 'create',
              name: 'Novo Papel',
              component: Permission
            },
            {
              path: 'edit/:id',
              name: 'Editar Papeis',
              component: Permission
            }
          ]
        },
        {
          path: 'about',
          name: 'Sobre',
          component: About
        }
      ]
    },
    {
      path: '/500',
      name: '500',
      component: Page500
    },
    {
      path: '*',
      name: '404',
      component: Page404
    }
  ]
})

/**
 * Authentication Logic
 */
router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']

  if (to.path.includes('/change-password')) {
    publicPages.push(to.path)
  }

  const authRequired = !publicPages.includes(to.path)

  const loggedIn = Cookies.getJSON(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
  const userId = Cookies.get(`${process.env.VUE_APP_COOKIES_PREFIX}user_id`)
  const token = Cookies.get(`${process.env.VUE_APP_COOKIES_PREFIX}token`)
  // const userHaveTwoFactorAuth = Cookies.get(`${process.env.VUE_APP_COOKIES_PREFIX}user_have_two_factor_auth`)

  // if (userHaveTwoFactorAuth && to.path !== '/validate-token') {
  //   Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
  //   Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}user_id`)
  //   Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}link`)
  //   next('/validate-token')
  // }

  if (loggedIn) {
    await router.app.$store.dispatch('auth/login', token)

    if (loggedIn.pass_expired === 1) {
      Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
      Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}user_id`)
      Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}link`)
      Cookies.set(
        `${process.env.VUE_APP_COOKIES_PREFIX}user_id`,
        loggedIn.data.user.id
      )

      const token = await AuthService.passExpired(loggedIn.data.user.id)

      next(`/change-password/expired/${token}`)
    }
  }

  if (authRequired && !loggedIn) {
    Cookies.set(`${process.env.VUE_APP_COOKIES_PREFIX}link`, to.path)
    return next('/login')
  } else if (from.path === '/login' && !userId && loggedIn) {
    const link = Cookies.get(`${process.env.VUE_APP_COOKIES_PREFIX}link`)

    if (link) {
      Cookies.remove(`${process.env.VUE_APP_COOKIES_PREFIX}link`)
      return next(`${link}`)
    }

    next()
  }

  router.app.$root.$emit('update:routes')

  next()
})

export default router
