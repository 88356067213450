import AuthService from '../services/auth.service'
import PermissionService from '../services/permission.service'

import Cookies from 'js-cookie'
import { uniq } from 'lodash'

const user = Cookies.getJSON(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
const initialState = user
  ? { status: { loggedIn: true }, user: user, permissions: null }
  : { status: { loggedIn: false }, user: null, permissions: null }

export const auth = {
  namespaced: true,

  state: initialState,

  actions: {
    async login({ commit }) {
      try {
        const whoiam = await AuthService.whoiam()
        let permissions = []

        const isMaster = whoiam.data.roles.filter((role) => {
          return role.slug === 'master'
        })

        if (isMaster.length > 0) {
          const allPermissions = await PermissionService.getAll()
          permissions = [...allPermissions]
        } else {
          for (const role of whoiam.data.roles) {
            permissions = [...permissions, ...role.permissions]
          }
        }

        const permissionsSlug = permissions.map((permission) => {
          return permission.slug
        })

        const uniquePermissions = uniq(permissionsSlug)

        commit('LOGIN_SUCCESS', {
          user: whoiam.data,
          permissions: uniquePermissions
        })
      } catch (error) {
        console.log(error)
      }
    },

    logout({ commit }) {
      AuthService.logout()
      commit('LOGOUT')
    }
  },

  getters: {
    user(state) {
      return state.user
    },

    permissions(state) {
      return state.permissions
    },

    loggedIn(state) {
      return state.status.loggedIn
    }
  },

  mutations: {
    LOGIN_SUCCESS(state, { user, permissions }) {
      state.status.loggedIn = true
      state.user = user
      state.permissions = permissions
    },

    LOGIN_FAILURE(state) {
      state.status.loggedIn = false
      state.user = null
    },

    LOGOUT(state) {
      state.status.loggedIn = false
      state.user = null
    }
  }
}
