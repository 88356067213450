import axios from './axios.service'

const method = 'settings'
const other_method = 'settingsmedia'

class SettingsService {
  async listAll() {
    try {
      const response = await axios.get(`/${method}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async listAllMedias() {
    try {
      const response = await axios.get(`/${other_method}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async setSettings(settings) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data: {
          settings
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async setSettingsMedia(settings) {
    return new Promise((resolve, reject) => {
      try {
        const response = axios({
          method: 'post',
          url: `/${other_method}`,
          data: {
            settings
          }
        })
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default new SettingsService()
